import '../components/components.css';

function Loading() {
    return (
        <div className="loading">
           <div className="loader">Laddar...</div>
        </div>
    );
}

export default Loading;