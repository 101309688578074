function Kontakt() {
    return (
        <div className="Kontakt">
            <h1 className="header-center">Kontakt</h1>
            <hr/>
            <article>
                <p>
                    Här kan du kontakta oss angående allmän intresseanmälan eller om du har några 
                </p>
            </article>
        </div>
    );
}
  
export default Kontakt;